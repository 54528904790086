<template>
  <div class="p-4">
    <a-button
        class="float-right mb-3 d-flex align-items-center"
        type="primary"
        @click="openWindow()"
    >
      <template #icon><i class="bi bi-bootstrap-reboot mr-1 mb-1"></i></template>
      Refresh Token
    </a-button>
    <a-table
        :columns="columns"
        :data-source="getUserPoolConfig()"
        bordered
        :scroll="{ y: '55vh', x: '900px' }"
        v-if="Object.keys(userPoolConfig).length > 0">
      <template #title>
        <a-typography-title :level="5">
          User pool config: &nbsp;
        </a-typography-title>
      </template>
    </a-table>
  </div>
</template>


<script>

import {mapActions, mapGetters} from "vuex";
import {useToast} from 'vue-toastification';

const toast = useToast();

export default {

  data() {
    return {
      tokenInterval: null,
      columns: [
        {
          title: "Client Id",
          dataIndex: "clientId",
          width: "30%",
          align: "left"
        },
        {
          title: "AWS Lambda token",
          dataIndex: "token",
          width: "70%",
          align: "left"
        },
      ],
    }
  },
  mounted() {
    const token = this.$route.hash.split("&")[0].replace("#id_token=", "");
    if (token) {
      this.createAwsLambdaToken({"token": token});
      localStorage.setItem("retro-lambda-token", token);
      setInterval(window.close, 100)
    } else {
      this.fetchUserPoolConfig();
    }
  },
  computed: {
    ...mapGetters(["userPoolConfig"]),
  },
  methods: {
    ...mapActions(["fetchUserPoolConfig", "createAwsLambdaToken"]),
    getUserPoolConfig() {
      return [this.userPoolConfig]
    },
    checkToken() {
      const token = localStorage.getItem("retro-lambda-token")
      if (token) {
        clearInterval(this.tokenInterval)
        this.userPoolConfig["token"] = token;
        localStorage.removeItem("retro-lambda-token")
        toast.success("Token refresh successfully")
      }
    },
    openWindow() {
      this.tokenInterval = setInterval(this.checkToken, 1000)
      const {clientId, responseType, scope, url} = this.userPoolConfig
      const redirectUrl = window.location.origin + this.$route.path;
      const authUrl = `${url}?client_id=${clientId}&response_type=${responseType}&scope=${scope}&redirect_uri=${redirectUrl}`
      window.open(
          authUrl,
          "awsGoogleAuth",
          "location,toolbar,resizable,scrollbars,status,width=500,height=500"
      );
    },
  }
};

</script>
